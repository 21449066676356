.containerr{
    width: 100vw;
    height: 100vh;
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
    
    background-position: center;
    background-size: cover;
    padding: 0 8%;

}
.logo{
    width: 300px;
    padding: 20px 0;
    cursor: pointer;

}
.content{
    /* top: 50%;
    position: absolute;
    transform: translateY(-50%); */
    color: #fff;

}
.content h1{
    font-size: 64px;
    font-weight: 600;
    color: #008080; /* Teal text */

}
.content h1 span{
    color:rgb(14, 231, 14);
}
.content  p{
    color:black;
}
.content button{
    background: transparent;
    border: 2px solid #fff;
    outline: none;
    padding: 12px 25px;
    color: #fff;
    display: flex;
    align-items: center;
    margin-top: 30px;
    cursor: pointer;

}
.content button img{
    width: 15px;
    margin-left: 10px;
}
.launch-time{
    display: flex;
}
.launch-time div{
    flex-basis: 200px;

}
.launch-time div p{
    font-size: 60px;
    margin-bottom: -4px;

}
.rocket{
    width: 250px;
    position: absolute;
    right: 10%;
    bottom: 0;
    animation: rocket 4s linear infinite;

}
@keyframes rocket{
    0%{
        bottom: 0;
        opacity: 0;
    }
    100%{
        bottom: 105%;
        opacity: 1;
    }
}


.pl {
    width: 6em;
    height: 6em;
  }
  
  .pl__ring {
    animation: ringA 2s linear infinite;
  }
  
  .pl__ring--a {
    stroke: #f42f25;
  }
  
  .pl__ring--b {
    animation-name: ringB;
    stroke: #f49725;
  }
  
  .pl__ring--c {
    animation-name: ringC;
    stroke: #255ff4;
  }
  
  .pl__ring--d {
    animation-name: ringD;
    stroke: #f42582;
  }
  
  /* Animations */
  @keyframes ringA {
    from, 4% {
      stroke-dasharray: 0 660;
      stroke-width: 20;
      stroke-dashoffset: -330;
    }
  
    12% {
      stroke-dasharray: 60 600;
      stroke-width: 30;
      stroke-dashoffset: -335;
    }
  
    32% {
      stroke-dasharray: 60 600;
      stroke-width: 30;
      stroke-dashoffset: -595;
    }
  
    40%, 54% {
      stroke-dasharray: 0 660;
      stroke-width: 20;
      stroke-dashoffset: -660;
    }
  
    62% {
      stroke-dasharray: 60 600;
      stroke-width: 30;
      stroke-dashoffset: -665;
    }
  
    82% {
      stroke-dasharray: 60 600;
      stroke-width: 30;
      stroke-dashoffset: -925;
    }
  
    90%, to {
      stroke-dasharray: 0 660;
      stroke-width: 20;
      stroke-dashoffset: -990;
    }
  }
  
  @keyframes ringB {
    from, 12% {
      stroke-dasharray: 0 220;
      stroke-width: 20;
      stroke-dashoffset: -110;
    }
  
    20% {
      stroke-dasharray: 20 200;
      stroke-width: 30;
      stroke-dashoffset: -115;
    }
  
    40% {
      stroke-dasharray: 20 200;
      stroke-width: 30;
      stroke-dashoffset: -195;
    }
  
    48%, 62% {
      stroke-dasharray: 0 220;
      stroke-width: 20;
      stroke-dashoffset: -220;
    }
  
    70% {
      stroke-dasharray: 20 200;
      stroke-width: 30;
      stroke-dashoffset: -225;
    }
  
    90% {
      stroke-dasharray: 20 200;
      stroke-width: 30;
      stroke-dashoffset: -305;
    }
  
    98%, to {
      stroke-dasharray: 0 220;
      stroke-width: 20;
      stroke-dashoffset: -330;
    }
  }
  
  @keyframes ringC {
    from {
      stroke-dasharray: 0 440;
      stroke-width: 20;
      stroke-dashoffset: 0;
    }
  
    8% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -5;
    }
  
    28% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -175;
    }
  
    36%, 58% {
      stroke-dasharray: 0 440;
      stroke-width: 20;
      stroke-dashoffset: -220;
    }
  
    66% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -225;
    }
  
    86% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -395;
    }
  
    94%, to {
      stroke-dasharray: 0 440;
      stroke-width: 20;
      stroke-dashoffset: -440;
    }
  }
  
  @keyframes ringD {
    from, 8% {
      stroke-dasharray: 0 440;
      stroke-width: 20;
      stroke-dashoffset: 0;
    }
  
    16% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -5;
    }
  
    36% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -175;
    }
  
    44%, 50% {
      stroke-dasharray: 0 440;
      stroke-width: 20;
      stroke-dashoffset: -220;
    }
  
    58% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -225;
    }
  
    78% {
      stroke-dasharray: 40 400;
      stroke-width: 30;
      stroke-dashoffset: -395;
    }
  
    86%, to {
      stroke-dasharray: 0 440;
      stroke-width: 20;
      stroke-dashoffset: -440;
    }
  }


  .text-content{
    height: 50vh;
  }
 .all-products-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr));
    text-align: center;
    padding: 20px;
    gap: 20px;
    justify-content: center;
    width: 100%;
    max-width: 100%;
  }
  
  .all-products-item {
    background-color: #f9f9f9;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    border-radius: 8px;
    padding: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .all-products-item .image-container {
    width: 100%;
    height: 220px;
  }
  
  .product-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
    border-radius: 8px;
    margin-bottom: 16px;
  }
  
  .product-details {
    flex: 1;
    width: 100%;
    text-align: left;
    background-color: #f9f9f9;
    padding: 12px;
    border-radius: 8px;
  }
  
  .product-type {
    font-size: 14px;
    font-weight: bold;
    color: #0078d7;
    margin-bottom: 4px;
  }
  .product-type.yellow{
    color: rgb(208 208 26);
  }
  
  .product-name {
    font-size: 18px;
    font-weight: bold;
    color: #000;
    margin-bottom: 8px;
  }
  
  .product-price {
    font-size: 15px;
    color: #666;
    margin: 10px 0;
  }
  
  .edit-delete-buttons,
  .add-to-cart-button {
    margin-left: auto;
  }
  
  .edit-button,
  .delete-button,
  .add-to-cart-button {
    background-color: #0078d7;
    color: #fff;
    border: none;
    border-radius: 4px;
    padding: 8px 16px;
    font-size: 12px;
    cursor: pointer;
  }
  
  .edit-button {
    margin-right: 8px;
  }
  
  .delete-button,
  .add-to-cart-button {
    margin-left: 8px;
  }
  
  .delete-button {
    background-color: #d83b01;
  }
  
  .add-to-cart-button , .dropship-button{
    background-color: #ffffff;
    color: black;
    border-radius: 20px;
    border: 1px solid #ddd;
    text-decoration: none;
    padding: 3px 10px;
    min-width: 40%;
    display: block;
    opacity: 0.9;
transition: opacity 0.3s ease;

  }
  .dropship-button{
    background-color: blueviolet;
    color: white;
    font-size: 12px;
  }
  .dropship-button:hover , .add-to-cart-button:hover{
    opacity: 1;
  }

  .theprice{
    text-decoration: underline;
  }


  .product-cont{
    height: fit-content;
  }
.why-us-section{
    background-color: #F6F5F3;
    min-height: 100vh;
    font-weight: 500;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 80px;
    font-weight: bold;
    color: #7a7a7a;
    /* background: linear-gradient(135deg, #0b0f15, #1a1f2c); */
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #faeef7 0%, #b0c5bf 74%);
}

@media (max-width: 550px) {
    .why-us-section{
        font-size: 50px;
    }
}
@media (max-width: 450px) {
    .why-us-section{
        font-size: 35px;
    }
}
@media (max-width: 350px) {
    .why-us-section{
        font-size: 25px;
    }
}
.our-products h1{
    font-size: 60px;
    font-family:'Times New Roman', Times, serif;
}


.full-inventory{
    background-color: rgb(7, 182, 7);
    color: #fff;
padding: 15px 10px;
    text-align: center;
    text-decoration: none;
    margin-top: 40px;
    display: block;
    width: fit-content;
}

.full-inventory:hover{
    background-color: rgb(6, 143, 6) ;
}

.contact-section{
    padding: 55px 50px 30px 50px;
    /* background: linear-gradient(135deg, #0b0f15, #1a1f2c); */
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
}

.contact-section .container{
    max-width: 800px;
    color: #0b0f15;
}

.talk-to-us{
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
    /* background: linear-gradient(135deg, #0b0f15, #1a1f2c); */
overflow: hidden;
    text-align: center;
    color: #0b0f15;

    height: 100vh;
}
.talk-to-us .container{
    padding: 200px 20px;

}
.talk-to-us p{
    font-size: 20px;
    margin-bottom: 40px;
letter-spacing: 4px;    
}

.talk-to-us a{
    /* color: #fff; */
    color: #0b0f15;

    font-size: 5vw;
    text-decoration: none;
}
.talk-to-us a:hover{
    text-decoration: underline;
}

.video-section {
    position: relative;
    height: 100vh;
    overflow: hidden;
    background-color: black;
  }
  /* .video-section::before{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    content: '';
    background-color:rgba(0, 0, 0, 0.39);
    z-index: 1;
  } */
  
  .video-section .video-text{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color:rgba(0, 0, 0, 0.39);
text-align: center;
  }

.video-text div{
    margin: 200px 0;
}

.video-text div h1{
    color: white;

}

.video-text div a{
    text-decoration: none;
    background-color: white;
    padding: 10px 15px;
    border-radius: 25px;
    color: black;
    font-weight: 500;
    font-size: 18px;
    letter-spacing: 2px;
    transition: all 0.3s ease-in-out;
    display: block;
    width: fit-content;
    margin: auto;
    margin-top: 50px;
border: 1px solid transparent;
}


.video-text div a:hover{
    background-color: transparent;
    border: 1px solid blue;
    color: white;
}
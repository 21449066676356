.login-container {
    font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
      "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
    /* width: 100vw; */
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background: linear-gradient(135deg, #0b0f15, #1a1f2c); */
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
  }
  .login-container form {
    width: 360px;
    height: auto;
    background-color: rgb(255, 255, 255);
    border-radius: 10px;
  }
  .form-container {
    margin: 20px 20px;
  }
  .mb {
    margin-bottom: 30px;
  }
  .form-sections {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .heading-container {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .heading {
    font-size: 1.5rem;
    text-transform: uppercase;
  }
  .form-fields {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 5px 10px;
  }
  label {
    font-size: 1.1rem;
  }
  .form-fields input {
    height: 40px;
    width: 100%;
    font-size: 1rem;
    outline: none;
    border-radius: 5px;
    padding: 5px;
    border: 1px solid rgb(122, 115, 115);
  }
  .form-fields input:hover,
  input:focus {
    border: 1px solid rgb(137, 5, 170);
  }
  
  #pass-field-container {
    position: relative;
  }
  #pass {
    padding-right: 35px;
  }
  /* input[type="checkbox"] {
    position: absolute;
    right: 10px;
    top: 13px;
    accent-color: rgb(204, 0, 255);
    height: 15px;
    width: 15px;
    padding: 5px;
    cursor: pointer;
  } */
  .form-fields .login-btn {
    height: 40px;
    font-size: 1rem;
    background-color: rgb(204, 0, 255);
    outline: none;
    border: none;
    color: white;
    font-weight: bold;
    /* cursor: pointer; */
  }
  .form-fields .login-btn:disabled {
    height: 40px;
    font-size: 1rem;
    background-color: rgba(204, 0, 255, 0.423);
    outline: none;
    border: none;
    color: white;
    font-weight: bold;
cursor: not-allowed;
}


  .forgot-password button {
    color: rgb(131, 0, 163);
    font-weight: 700;
    text-decoration-line: none;
    padding-left: 8px;
    border: none;
    background-color: transparent;
  }
  
.plans{
    /* background: linear-gradient(135deg, #0b0f15, #1a1f2c); */
    background-color: #fee2f8;
    background-image: linear-gradient(315deg, #fee2f8 0%, #dcf8ef 74%);
    padding: 50px 0;
    position: relative;
    /* min-height: 80vh; */
    
}


.plans h1{
    font-size: 55px;
margin-bottom: 50px;
color: #008080; /* Teal text */

}

.plans input:not([type = "checkbox"]) , .PhoneInput{
    display: block;
    width: 75%;
    padding: 2px 10px;
    border-radius: 7px;
    border: 1px solid #bfb4b4;
}


.plans input[type = "checkbox"]{
width: 30px;
height: 30px;
margin-left: 20px;
}


@media (max-width:700px) {
    .b2bheader{
    font-size: 40px !important;
    }
    
}
@media (max-width:400px) {
    .b2bheader{
    font-size: 30px !important;
    }
    
}